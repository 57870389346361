import React from 'react'
import LeadsModel from './leads/model/LeadsModel'
import LeadsList from './leads/LeadsList'
import TranslationProvider from './providers/TranslationProvider'
import { FormattedMessage } from 'react-intl'

export default class LeadsApp extends React.Component {
  constructor (props) {
    super(props)

    this.model = new LeadsModel({
      openLeads: props.openLeads || [],
      closedLeads: props.closedLeads || []
    })

    this.locale = props.locale
  }

  render () {
    return (
        <TranslationProvider user_locale={this.locale}>
          <div className='leads_main w-100'>
            <div className='leads_header pb-2'>
              <FormattedMessage tagName='h1' id='leads.applications_list'/>
            </div>
            <div className='leads_content'>
              <div className='leads_controlls'>
                <FormattedMessage id='leads.applications_number'>
                  {text => <p>{text + this.model.clientsCount}</p>}
                </FormattedMessage>
              </div>
              <LeadsList model={this.model} />
            </div>
          </div>
        </TranslationProvider>
    )
  }
}
