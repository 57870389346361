import { action, makeObservable, observable } from 'mobx'

type Lead = {
    id: number
    name: string
    phone: string
    notes: string
    budget: string
    date: string
    status: string
}

interface ILeadsModelProps {
    openLeads: Lead[] | []
    closedLeads: Lead[] | []
}

class LeadsModel {
  clientsCount: number
  leadsList: Lead[] | []
  isPopupOpened: boolean

  constructor ({ openLeads, closedLeads }: ILeadsModelProps) {
    this.clientsCount = (openLeads || []).length + (closedLeads || []).length
    this.leadsList = [...openLeads, ...closedLeads] // TO CHANGE
    this.isPopupOpened = false

    makeObservable(this, {
      leadsList: observable,
      isPopupOpened: observable,
      setIsPopupOpened: action
    })
  }

  setIsPopupOpened (isOpened: boolean) {
    this.isPopupOpened = isOpened
  }
}

export default LeadsModel
