import React from 'react'
import { observer } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import CopyIcon from '../../../assets/images/leads/icons/CopyIcon.svg'
import CloseIcon from '../../../assets/images/leads/icons/CloseIcon.svg'
import DownArrowIcon from '../../../assets/images/leads/icons/DownArrowIcon.svg'
import LeadsPopUp from './LeadsPopUp'

const LeadsList = observer(({ model }) => {
  return (
    <div className='leads_list'>
        <table className='w-100 desktop_table'>
            <thead className='table_header'>
            <tr>
                <th className='table_count'></th>
                <FormattedMessage tagName='th' id='spa.name' />
                <FormattedMessage tagName='th' id='guests.phone' />
                <FormattedMessage tagName='th' id='guests.comment' />
                <FormattedMessage tagName='th' id='simple_event.date_and_time' />
                <th className='table_controlls'></th>
            </tr>
            </thead>
            <tbody className='table_body'>

            {/* LEADS LIST */}
            {model.leadsList.map(lead => (
                <tr className='table_body-row' key={lead.id}>
                <td className='table_number'>{lead.id}</td>
                <td>{lead.name}</td>
                <td>
                {lead.phone &&
                <div className='d-flex align-items-center gap-2'>
                    <div>{lead.phone}</div>
                    {lead.phone.length > 0 && <button className='copy_button'><img src={CopyIcon} alt='Copy' /></button>}
                </div>}
                </td>
                {lead.notes.length > 30
                  ? <td>
                        <p>{lead.notes.slice(0, 31)}...</p>
                        <u onClick={() => model.setIsPopupOpened(true)}>
                            <FormattedMessage id='leads.see_more'>
                                {text => text}
                            </FormattedMessage>
                        </u>
                        {model.isPopupOpened && <LeadsPopUp notes={lead.notes} model={model} />}
                    </td>
                  : <td>
                        <p>{lead.notes}</p>
                    </td>
                }
                <td>{lead.date}</td>
                <td className='table_controlls'>
                <div className='d-flex gap-2'>
                    <button className='submit'>
                    <img src={DownArrowIcon} alt='Submit'/>
                    </button>
                    <button className='delete'>
                    <img src={CloseIcon} alt='Delete'/>
                    </button>
                </div>
                </td>
                </tr>
            ))}
            </tbody>
        </table>

        {/* LEADS MOBILE */}
        <table className='w-100 mobile_table table_body'>
            {model.leadsList.map(lead => (
                <tbody className='table_body-row' key={lead.id}>
                    <tr>
                        <td className='table_number'>{lead.id}</td>
                        <td></td>
                    </tr>
                    <tr>
                        <FormattedMessage tagName='th' id='spa.name' />
                        <td>{lead.name}</td>
                    </tr>
                    <tr>
                        <FormattedMessage tagName='th' id='guests.phone' />
                        <td>
                            {lead.phone &&
                                <div className='d-flex align-items-center gap-2'>
                                <div>{lead.phone}</div>
                                {lead.phone.length > 0 && <button className='copy_button'><img src={CopyIcon} alt='Copy' /></button>}
                                </div>
                            }
                        </td>
                    </tr>
                    <tr>
                        <FormattedMessage tagName='th' id='guests.comment' />
                        {lead.notes.length > 30
                          ? <td>
                                <p>{lead.notes.slice(0, 31)}...</p>
                                <u onClick={() => model.setIsPopupOpened(true)}>
                                    <FormattedMessage id='leads.see_more'>
                                        {text => text}
                                    </FormattedMessage>
                                </u>
                                {model.isPopupOpened && <LeadsPopUp notes={lead.notes} model={model} />}
                            </td>
                          : <td>
                                <p>{lead.notes}</p>
                            </td>
                        }
                    </tr>
                    <tr>
                        <FormattedMessage tagName='th' id='simple_event.date_and_time' />
                        <td>{lead.date}</td>
                    </tr>
                    <tr>
                        <td className='table_controlls'>
                            <div className='d-flex gap-2'>
                                <button className='submit'>
                                <img src={DownArrowIcon} alt='Submit'/>
                                </button>
                                <button className='delete'>
                                <img src={CloseIcon} alt='Delete'/>
                                </button>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            ))}
        </table>
    </div>
  )
})

export default LeadsList
